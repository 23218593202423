import { Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import Langu from "../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Product } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface dataProductsProps {
    Product: Product;
    dragging: boolean;
}

const ClientCardPeoduct: React.FC<dataProductsProps> = ({ Product }) => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const [colorActive, setColorActive] = useState<string>(Product?.colors?.[0]!)
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency } = context!

    useEffect(() => {
        setColorActive(Product?.colors?.[0]!)
    }, [Product])

    const truncateText = (text: string): string => {
        let shortText = text.substring(0, 50)
        if (text.length > 50) {
            shortText = shortText + "..."
        }
        return shortText
    }
    const extractTextFromElement = (element: React.ReactNode): string => {
        // التأكد من أن العنصر هو نص (string)
        if (typeof element === "string") {
            return element;
        }
        // إذا كان العنصر JSX، نحاول استخراج النص من داخله
        if (React.isValidElement(element) && element.props.children) {
            return String(element.props.children);
        }
        return "";
    };

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    return (<>
        <div style={{ position: "relative", width: isMobile ? "49%" : "" }}>
            <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "column", border: "1px solid #dddddd", alignItems: "center", width: "100%", cursor: "pointer", position: "relative", minHeight: isMobile ? 370 : 450 }}>
                {
                    isMobile ?
                        <div style={{ borderRadius: "5px", width: isMobile ? "100%" : 300, height: isMobile ? "100%" : 300 }} onClick={() => nav(`/client/market/productDetails/${Product?._id}`)}>
                            <img draggable={false} style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                        </div>
                        :
                        <div className="cardHome" style={{ borderRadius: "5px", width: isMobile ? "100%" : 300, height: isMobile ? "100%" : 300 }} onClick={() => nav(`/client/market/productDetails/${Product?._id}`)}>
                            <img draggable={false} style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                            <img draggable={false} className="imgHomeHover2" style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                        </div>
                }
                {
                    Product.offers &&
                    <div style={isMobile ? { position: "absolute", top: Product.disable ? "50px" : "10px", left: "10px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: "65px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }
                        :
                        { position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={
                        isMobile ? { position: "absolute", top: "10px", left: "10px", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: "65px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }
                            :
                            { position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                onClick={() => setColorActive(color)}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                            </Button>
                        ))}
                    </div>
                }
                <div style={{ fontSize: "20px", padding: "5px", maxWidth: 300 }}>{truncateText(extractTextFromElement(Langu(Product.product!)))}</div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {finshNumber(Product.price!).toLocaleString('en-US') + ' ₺'}
                                </div>
                                <div style={{ color: "red" }}>
                                    {finshNumber(Product.newPrice!).toLocaleString('en-US') + ' ₺'}
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray" }}>
                                {finshNumber(Product.price!).toLocaleString('en-US') + ' ₺'}
                            </div>
                    }
                </div>
            </div>
        </div>
    </>)
}

export default ClientCardPeoduct;
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../../components/context/ChatContext";

interface BtnCreateProductProps {
    productEN: string;
    productAR: string;
    productHE: string;
    informationEN: string;
    informationAR: string;
    informationHE: string;
    price: number;
    newPrice: number;
    number: number;
    photo: File[];
    category: string;
    brand: string;
    offerStatus: boolean;
    colorsPost: (string | undefined)[];
    statusCategory?: string;
    classification?: string;
    Categorys?: Record<string, string>;
}


const BtnCreateProduct: React.FC<BtnCreateProductProps> = ({ productEN, productAR, productHE, informationEN, informationAR, informationHE, price, number, newPrice, photo, category, brand, offerStatus, colorsPost, statusCategory, classification, Categorys }) => {
    let userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Product"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [errInput, setErrInput] = useState<string>('')

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    useEffect(() => {
        setErrInput('')
    }, [productEN, productAR, productHE, informationEN, informationAR, informationHE, price, number, photo])

    const BtnCreate = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        if (!productEN || !productAR || !productHE || !informationEN || !informationAR || !informationHE || !price || !number) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Product"))
            setErrInput(t('Market.Please enter data'))
            return;
        }
        if (photo.length == 0) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Product"))
            setErrInput(t('Market.Please enter photos'))
            return;
        }

        const Category = JSON.parse(category)
        const Brand = JSON.parse(brand)

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("categoryEN", Category.en)
        updatedFormData.append("categoryAR", Category.ar)
        updatedFormData.append("categoryHE", Category.he)
        updatedFormData.append("brandEN", Brand.en)
        updatedFormData.append("brandAR", Brand.ar)
        updatedFormData.append("brandHE", Brand.he)
        updatedFormData.append("productEN", productEN)
        updatedFormData.append("productAR", productAR)
        updatedFormData.append("productHE", productHE)
        updatedFormData.append("informationEN", informationEN)
        updatedFormData.append("informationAR", informationAR)
        updatedFormData.append("informationHE", informationHE)
        updatedFormData.append("colors", JSON.stringify(colorsPost))
        if (statusCategory !== "null") {
            updatedFormData.append("statusCategory", statusCategory!)
        }
        if (classification !== "null2") {
            updatedFormData.append("classification", classification!)
        }
        if (price !== undefined) {
            if (offerStatus && newPrice !== undefined) {
                updatedFormData.append("price", (price / baseCurrency).toString());
                updatedFormData.append("newPrice", (newPrice / baseCurrency).toString());
                updatedFormData.append("offers", offerStatus.toString());
            }
            if (discount !== undefined) {
                updatedFormData.append("discount", discount.toFixed(0).toString());
            }
            else {
                updatedFormData.append("price", (price / baseCurrency).toString());
            }
        }
        if (Categorys) {
            updatedFormData.append("categorys", JSON.stringify(Categorys))
        }
        if (number !== undefined) {
            updatedFormData.append("number", number.toString());
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Products/PostProduct`, updatedFormData,
            {
                headers: {
                    Authorization: "Bearar " + token,
                    "Content-Type": "multipart/form-data"
                }
            }
        )
            .then((doc) => console.log(doc))
            .catch(err => console.log(err))
        setStatusBtnCreate(t("Market.Create Product"))
        setDisabledBtnCreate(false)
    }

    return (<>
        <div>
            <div style={{ color: "red" }}>{errInput}</div>
            <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
        </div>
    </>)
}

export default BtnCreateProduct;
import { useNavigate } from "react-router";
import { Category } from "../../Interfaces";
import { useContext } from "react";
import { ChatContext } from "../../components/context/ChatContext";


interface CardCategoryProp {
    category: Category;
}


const CardCategory: React.FC<CardCategoryProp> = ({ category }) => {
    const nav = useNavigate()

    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    return (<>
        <div style={{ width: isMobile ? "49%" : 300, position: "relative" }}>
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", border: "1px solid #dddddd", alignItems: "center", width: "100%", cursor: "pointer" }}>
                <div style={{ borderRadius: "5px" }} onClick={() => nav(`/guest/category/${category?.name.en}`)}>
                    <img draggable={false} style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${category.file ? category.file?.[1] : ""}`} />
                </div>
            </div>
        </div>
    </>)
}

export default CardCategory;
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Cookies from "universal-cookie";
import { Loading } from "../laoding/laoding";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userSlice";
import { RootState } from "../../redux/store";
import { isExpired } from "react-jwt";
import axios from "axios";



const RefreshPage: React.FC = () => {
    const cookie = new Cookies();
    const [loading, setLoading] = useState(true);
    const nav = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData)
    let ReducerToken = userData?.user?.token

    useEffect(() => {
        const getTokenCookie = cookie.get("bearer");
        const TokenCookie = getTokenCookie?.token
        const UserCookie = getTokenCookie?.userDetals
        const RefreshToken = getTokenCookie?.refreshToken
        const IsExpiredTokenCookie = isExpired(TokenCookie)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000); // 15 ثانية

        async function refresh() {
            if (TokenCookie) {
                if (IsExpiredTokenCookie) {
                    await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + RefreshToken,
                        }
                    })
                        .then((doc) => {
                            const token = doc.data.token;
                            const userDetals = doc.data.data
                            cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                            dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log("err refreshPage : ", err);
                            setLoading(false);
                        })
                } else {
                    cookie.set("bearer", { token: TokenCookie, refreshToken: RefreshToken, userDetals: UserCookie }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: TokenCookie, refreshToken: RefreshToken, userDetals: UserCookie }))
                    setLoading(false);
                }
            } else {
                nav("/guest/homePage");
            }
        }
        if (!ReducerToken) {
            refresh();
        } else {
            setLoading(false);
        }
        return () => clearTimeout(timer);
    })
    return loading ? <Loading /> : <Outlet />;
}
export default RefreshPage;


import { useContext, useEffect, useState } from "react";
import img1 from "../../../../files/HomeGuest/شعار أبو أحمد معدل مقصوص.jpg";
import axios from "axios";
import { useParams } from "react-router";
import { OrderProps } from "../getOrders/GetOrders.admin";
import { Button, Table } from "react-bootstrap";
import Langu from "../../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import './Order.css'
import { ChatContext } from "../../../../components/context/ChatContext";

const InvoiceForPrinting = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const params = useParams();
    const IdOrder = params.id;
    const { t } = useTranslation();
    const [dataOrder, setDataOrder] = useState<OrderProps>();
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_URL_API}/API/Orders/GetOrder/${IdOrder}`,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    },
                }
            )
            .then((doc) => setDataOrder(doc.data))
            .catch((err) => console.log(err));
    }, []);

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            <div className="invoice-container" style={isMobile ? { padding: 0 } : {}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                        style={{
                            width: "100%",
                            maxWidth: 1040,
                            padding: isMobile ? 0 : "0 40px",
                            margin: "50px 0",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0px 0",
                                borderBottom: `2px #dddddd solid`,
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 50,
                                    color: "#103280",
                                    alignItems: "center",
                                    display: "flex",
                                    borderBottom: `4px #103280 solid`,
                                }}
                            >
                                <p>Invoice</p>
                            </div>
                            <div style={isMobile ? { width: "70%", textAlign: "end" } : {}}>
                                <img style={isMobile ? { width: "70%" } : {}} src={img1} />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "30px 0",
                            }}
                        >
                            <div>
                                <div>{dataOrder?.userName}</div>
                                <div>{dataOrder?.phone}</div>
                            </div>
                            <div>
                                <div>Invoice#</div>
                                {dataOrder?._id}
                            </div>
                        </div>
                        <Table striped style={{ border: "1px solid #dddddd" }}>
                            <thead style={{ backgroundColor: "black" }}>
                                <tr style={{ backgroundColor: "black" }}>
                                    <th
                                        style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            textAlign: "start",
                                        }}
                                    >
                                        #
                                    </th>
                                    <th
                                        style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            textAlign: "center",
                                        }}
                                    >
                                        {t("Market.product")}
                                    </th>
                                    <th
                                        style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            textAlign: "center",
                                        }}
                                    >
                                        {t("Market.price")}
                                    </th>
                                    <th
                                        style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            textAlign: "center",
                                        }}
                                    >
                                        {t("Market.Quantity")}
                                    </th>
                                    <th
                                        style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            textAlign: "end",
                                        }}
                                    >
                                        {t("Market.Total price")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataOrder?.dataItems?.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "start" }}>
                                            {index + 1}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                display: "flex",
                                                gap: 10,
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Langu {...item?.product!} />{" "}
                                            {item.color && (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: 15,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            minWidth: 20,
                                                            maxWidth: 20,
                                                            minHeight: 20,
                                                            maxHeight: 20,
                                                            backgroundColor:
                                                                item.color,
                                                        }}
                                                    ></div>
                                                </div>
                                            )}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {item.price} ₺
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {item.quantity}
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                            {Number(item.price) *
                                                Number(item.quantity)}{" "}
                                            ₺
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "30px 0",
                            }}
                        >
                            <div>
                                <div>{t("Market.PayPage.Subtotal")}</div>
                                <div>{t("Market.PayPage.shipping")}</div>
                                <div>{t("Market.PayPage.Total")}</div>
                            </div>
                            <div>
                                <div>{dataOrder?.totalPrice}</div>
                                <div>0</div>
                                <div>{Number(dataOrder?.totalPrice) + 0}</div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <Button bsPrefix="BtnBNB" className="no-print" onClick={handlePrint}>{t("Market.Print Invoice")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceForPrinting;

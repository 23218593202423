import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ModalDeleteCategoryProps {
    show: boolean;
    ModalDeleteCategory: React.Dispatch<React.SetStateAction<boolean>>;
    id: string
}

const ModeleDeleteCategory: React.FC<ModalDeleteCategoryProps> = ({ show, ModalDeleteCategory, id }) => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()

    const DeleteBtn = async () => {
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Products/DeleteCategory/${id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => ModalDeleteCategory(false))
            .catch((err) => console.log(err))
    }

    return (<>
        <Modal show={show} onHide={() => ModalDeleteCategory(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>
                    {t("Home.Confirmation message")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t("Home.Do you really want to delete the category?")}
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => ModalDeleteCategory(false)}>{t("Hedar.Close")}</Button>
                <Button variant="danger" onClick={DeleteBtn}>{t("Hedar.Delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModeleDeleteCategory;
import { useTranslation } from "react-i18next";

interface Language {
    ar: string;
    en: string;
    he: string;
}

const Langu = (data: Language) => {
    const { i18n } = useTranslation();

    const languge = (eve: Language): string => {
        switch (i18n.language) {
            case "ar":
                return eve?.ar;
            case "en":
                return eve?.en;
            default:
                return eve?.he;
        }
    };

    return (<>{languge(data)}</>);
}

export default Langu;

import { Stack } from "react-bootstrap";
import { UseFetchRecipientUser } from "./UseFetchRecipientUser";
import { useContext } from "react";
import { ChatContext } from "../context/ChatContext";
import { UnreadNotification } from "./UnradeNotification";
import { UseFecrhLatesMessage } from "./UseFecrhLatesMessage";
import moment from "moment";
import axios from "axios";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { ChatProps } from "../../Interfaces";


interface UserChatProps {
    chat?: ChatProps;
    user?: {
        _id?: string;
    }
};



const UserChat: React.FC<UserChatProps> = ({ chat, user }) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { recipientUser } = UseFetchRecipientUser({ chat, user })
    const chatContext = useContext(ChatContext);

    const { latestMessage } = UseFecrhLatesMessage(chat!)
    const { onlineUsers, notification, markThisUserNotificationsAsRead, notificationDB } = chatContext!
    const unreadNotification = UnreadNotification({ notificationDB })
    const ThisUserNotifications = unreadNotification.filter((n) => n.senderId === recipientUser?._id)
    const isOnline = onlineUsers?.some((onlineUser) => onlineUser?.userId === recipientUser?._id)

    const truncateText = (text: string) => {
        let shortText = text.substring(0, 20)
        if (text.length > 20) {
            shortText = shortText + "..."
        }
        return shortText
    }

    return <>
        <Stack onClick={async () => {
            if (markThisUserNotificationsAsRead?.length !== 0) {
                markThisUserNotificationsAsRead(ThisUserNotifications, notification)
            }
            await axios.put(`${process.env.REACT_APP_URL_API}/API/Message/IsReadMessage`, { chatId: chat?._id }, {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
        }}
            style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid #dddddd", minHeight: "70px", alignItems: "center", padding: "10px" }}>
            <div>
                <div style={{ fontWeight: "500", fontSize: "20px", display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                    <div>{recipientUser?.userName}</div>
                    {
                        isOnline ? <span style={{ width: "10px", height: "10px", backgroundColor: "green", borderRadius: "50%", textAlign: "end" }}>{"   "}</span>
                            :
                            <span></span>
                    }
                </div>
                <div style={{ color: "gray", fontSize: "14px" }}>
                    {latestMessage?.text && (<span>{truncateText(latestMessage?.text)}</span>)}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: 'flex-end', position: "relative" }}>
                <div style={ThisUserNotifications.length > 0 ? { width: '23px', height: "23px", borderRadius: "50%", backgroundColor: "red", textAlign: 'center', color: "white", position: "absolute", top: "-20px", left: "-20px" } : {}}>{ThisUserNotifications.length > 0 && ThisUserNotifications.length}</div>
                <div style={{}}>
                    {moment(latestMessage?.createdAt).calendar()}
                </div>
            </div>
        </Stack >
    </>
}

export default UserChat;
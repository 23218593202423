import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './payPage.css'
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { cleenItems } from "../../../redux/counterSlice";
import { useNavigate } from "react-router";
import { UsersProps } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";


const PaymentMethods: React.FC = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!
    const [dataUserId, setDataUserId] = useState<UsersProps>()
    const [country, setCountry] = useState<string | undefined>(dataUserId ? dataUserId?.address?.country : "")
    const [neighborhood, setNeighborhood] = useState<string | undefined>(dataUserId ? dataUserId?.address?.neighborhood : "")
    const [street, setStreet] = useState<string | undefined>(dataUserId ? dataUserId?.address?.street : "")
    const [buildingNumber, setBuildingNumber] = useState<string | number | undefined>(dataUserId ? dataUserId?.address?.buildingNumber : "")
    const [floor, setFloor] = useState<number | undefined>(dataUserId ? dataUserId?.address?.floor : 0)
    const [apartmentNo, setApartmentNo] = useState<number | undefined>(dataUserId ? dataUserId?.address?.apartmentNo : 0)

    useEffect(() => {
        setCountry(dataUserId ? dataUserId?.address?.country : "")
        setNeighborhood(dataUserId ? dataUserId?.address?.neighborhood : "")
        setStreet(dataUserId ? dataUserId?.address?.street : "")
        setBuildingNumber(dataUserId ? dataUserId?.address?.buildingNumber : "")
        setFloor(dataUserId ? dataUserId?.address?.floor : 0)
        setApartmentNo(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    }, [dataUserId])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData?.user?.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUserId(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const [disabled, setDisabled] = useState<boolean>(false)
    const [statusBtn, setStatusBtn] = useState<any>(t("Market.PayPage.confirm"))

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    const subTotal: number = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + (finshNumber(cartitem.data.newPrice) || finshNumber(cartitem.data.price) || 0) * cartitem.quantity
    }, 0)
    const delivery = 0
    const totalPrice = Math.round(subTotal + delivery)
    const dataItems = CarentItem?.cartItems.map((item) => {
        return {
            itemId: item.data._id,
            product: item.data.product,
            price: item.data.price,
            newPrice: item.data.newPrice,
            offers: item.data.offers,
            quantity: item.quantity,
            color: item.color
        }
    })
    const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }


    const dataPostOrder = {
        userId: userData?.user?.userDetals?._id!,
        phone: userData?.user?.userDetals?.phone!,
        email: userData?.user?.userDetals?.email,
        totalPrice,
        paymentMethod: "card",
        dataItems,
        userName: userData.user.userDetals?.userName!,
        address: dataPutAddress,
    }

    const putAddressUser = async () => {
        setDisabled(true)
        setStatusBtn(<LoadingBtn />)
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutUserId/${userData?.user?.userDetals?._id}`,
            dataPutAddress,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(async () => {
                await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostOrder`, dataPostOrder, {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                })
                    .then((doc) => {
                        dispatch(cleenItems())
                        nav(`/client/market/invoice/${doc.data._id}`)
                    })
                    .catch(err => console.log(err))
            })
            .catch((err) => console.log(err))
        setStatusBtn(t("Market.PayPage.confirm"))
        setDisabled(false)
    }
    return (<>
        <div>
            <div style={{ marginBottom: "20px" }}>
                {t("Market.PayPage.addres")} :
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRadius: "10px" }}>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.PayPage.Country")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px", width: "50%" }}>
                        <Form.Control type="text" placeholder="" autoFocus onChange={(e) => setCountry(e.target.value)} defaultValue={dataUserId?.address?.country} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.PayPage.Neighborhood")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                        <Form.Control type="text" placeholder="" onChange={(e) => setNeighborhood(e.target.value)} defaultValue={dataUserId?.address?.neighborhood} />
                    </Form.Group>
                </div>

                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.PayPage.Street")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                        <Form.Control type="text" placeholder="" onChange={(e) => setStreet(e.target.value)} defaultValue={dataUserId?.address?.street} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label> {t("Market.PayPage.Building number")}:</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                        <Form.Control type="text" placeholder="" onChange={(e) => setBuildingNumber(e.target.value)} defaultValue={dataUserId?.address?.buildingNumber} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t('Market.PayPage.floor')} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                        <Form.Control type="number" placeholder="" onChange={(e) => setFloor(parseInt(e.target.value))} defaultValue={dataUserId?.address?.floor} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label> {t("Market.PayPage.Apartment No")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                        <Form.Control type="number" placeholder="" onChange={(e) => setApartmentNo(parseInt(e.target.value))} defaultValue={dataUserId?.address?.apartmentNo} />
                    </Form.Group>
                </div>
                <div>
                    <Button bsPrefix="BtnBNB" disabled={disabled} onClick={putAddressUser}>{statusBtn}</Button>
                </div>
            </div>

        </div>
    </>)
}

export default PaymentMethods;
import { Stack } from "react-bootstrap";
import Langu from "../languge/languge";
import { Product } from "../../Interfaces";
import { useContext } from "react";
import { ChatContext } from "../context/ChatContext";


interface CartItemCompletePageProps {
    data: {
        data: Product;
        quantity: number;
        color: string
    }
}

const CartItemCompletePage: React.FC<CartItemCompletePageProps> = ({ data }) => {
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    return (<>
        <Stack style={{ display: "flex", maxHeight: "100px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: "4px 0" }}>
            <img style={{ height: "80px", width: "100px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.data?.file?.[0]}`} alt={data.data.product?.en} />
            <div style={{ position: "revert", top: "-5px", width: "22px", display: "flex", justifyContent: "center" }}>
                x{data.quantity}
            </div>
            <div style={{ width: "20%" }}>
                {Langu(data?.data?.product!)}
            </div>
            {data.color &&
                <div style={{ width: '50px', textAlign: "center" }}>
                    <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: data.color }}></div>
                </div>
            }
            <div style={{ height: "100%", display: "flex", width: "20%", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ textAlign: "end" }}>
                    {
                        data.data.newPrice ?
                            <div>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {(finshNumber(data.data.price!) * data.quantity).toLocaleString('en-US')} ₺
                                </div>
                                <div style={{ color: "red" }}>
                                    {(finshNumber(data.data.newPrice!) * data.quantity).toLocaleString('en-US')} ₺
                                </div>
                            </div>
                            :
                            <div>
                                {(finshNumber(data.data.price!) * data.quantity).toLocaleString('en-US')} ₺
                            </div>
                    }
                </div>
            </div>
        </Stack>
    </>)
}


export default CartItemCompletePage;
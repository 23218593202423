import { Alert, Button } from "react-bootstrap";
import Langu from "../../../components/languge/languge";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface InfoProductProps {
    data: Product
}

const InfoProduct: React.FC<InfoProductProps> = ({ data }) => {
    const { t } = useTranslation()
    const [colorActive, setColorActive] = useState<string>(data?.colors?.[0]!)
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency } = context!

    useEffect(() => {
        setColorActive(data?.colors?.[0]!)
    }, [data])

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    return (<>
        <div style={{ padding: 10, borderBottom: "1px solid #dddddd" }}>
            <div style={{ margin: '10px 0' }}><Langu {...data.category!} /></div>
            <div style={{ fontWeight: 700, margin: '10px 0' }}><Langu {...data.product!} /></div>
            <div style={{ margin: '10px 0' }}><Langu {...data.brand!} /></div>
            <div style={{ margin: '10px 0' }}><Langu {...data.information!} /></div>
            <div style={{ width: "100%", fontSize: "18px", margin: '10px 0' }}>
                {
                    data.newPrice ?
                        <div style={{ display: "flex", width: "100%", justifyContent: "start", gap: '20px' }}>
                            <div>
                                {t('Market.price')} :
                            </div>
                            <div style={{ textDecoration: "line-through", color: "black", fontWeight: 700 }}>
                                {finshNumber(data.price!).toLocaleString('en-US') + ' ₺'}
                            </div>
                            <div style={{ color: "red", fontWeight: 700 }}>
                                {t('Market.newPrice')} : {finshNumber(data.newPrice!).toLocaleString('en-US') + ' ₺'}
                            </div>
                        </div>
                        :
                        <div style={{ textAlign: "start", color: "black", fontWeight: 700 }}>
                            {t('Market.price')} : {finshNumber(data.price!).toLocaleString('en-US') + ' ₺'}
                        </div>
                }
            </div>
            <div style={{ display: "flex" }}>
                {data.colors?.length! > 0 && data.colors?.map((color: string, index) => (
                    <Button
                        key={index}
                        onClick={() => setColorActive(color)}
                        style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                    </Button>
                ))}
            </div>
            <div style={{ margin: '10px 0' }}>
                {t('Market.number')} :  {data.disable ? 0 : data.number}
            </div>
            <div style={{ margin: '10px 0' }}>
                {t('Market.Sell from him')} :  {data.sold}
            </div>
        </div >
        <div style={isMobile ? { padding: 10, borderBottom: "1px solid #dddddd", width: "100%", display: "flex", justifyContent: "center" } : { padding: 10, borderBottom: "1px solid #dddddd" }}>
            <Button bsPrefix="BtnBNB" disabled={data?.disable} onClick={() => setShowAlirtMsg(true)}>{t("Market.add to cart")}</Button>
        </div>

        <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%" }} onClose={() => setShowAlirtMsg(false)} dismissible>
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Market.Error message")}
            </Alert.Heading>
            <p>
                {t("Market.You are not registered, please log in to continue")}
            </p>
        </Alert>
    </>)
}

export default InfoProduct;
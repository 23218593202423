import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UsersProps } from "../../Interfaces";
import { ChatContext } from "../../components/context/ChatContext";
import { LoadingBtn } from "../../components/laoding/laoding";


const Dashboard: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataUsers, setDataUsers] = useState<UsersProps[]>()
    const context = useContext(ChatContext)
    const { isMobile } = context!
    const [limit, setLimit] = useState<number>(40)
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUsers/${limit}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUsers(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }, [limit])

    const BtnViewMore = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 20)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? "0 10px" : "0 40px" }}>
                <Table striped>
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "start" }}>#</th>
                            <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.name")}</th>
                            <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.phone")}</th>
                            {!isMobile &&
                                <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.email")}</th>
                            }
                            <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.role")}</th>
                            <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.active")}</th>
                            <th style={{ width: "15%", backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "start" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataUsers?.map((user, index) =>
                                <tr key={user._id}>
                                    <td style={{ alignContent: "center", textAlign: "start" }}>{index + 1}</td>
                                    <td style={{ alignContent: "center", textAlign: "center" }}>{user.userName}</td>
                                    <td style={{ alignContent: "center", textAlign: "center" }}>{user.phone}</td>
                                    {!isMobile &&
                                        <td style={{ alignContent: "center", textAlign: "center" }}>{user.email}</td>
                                    }
                                    <td style={{ alignContent: "center", textAlign: "center" }}>{user?.role?.toString()}</td>
                                    <td style={{ alignContent: "center", textAlign: "center" }}>{user?.active?.toString()}</td>
                                    <td style={{ width: "15%", alignContent: "center", textAlign: "end" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/dashboard/userId/${user._id}`)}>{t("Market.Order details")}</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: 40 }}>
                    <Button bsPrefix="BtnBNB" onClick={BtnViewMore}>{statusBtnViewMore}</Button>
                </div>
            </div>
        </div>
    </>)
}
export default Dashboard;
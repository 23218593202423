import { useContext, useEffect, useState } from "react";
import Langu from "../../../components/languge/languge";
import ModelXLAdmin from "../../market/Modales/ModaleXL.admin";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Product } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";



const AdminYouMayAlsoLike: React.FC = () => {
    const { t } = useTranslation()
    const [showModalXL, setShowModalXL] = useState<any>({
        setShow: false,
        data: {}
    });
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataProducts(doc.data))
            .catch(err => console.log(err))
    }, [])

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    return (<>
        <div style={{ borderBottom: "1px solid #dddddd" }}>
            {
                dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 3).map((Product, index) => (
                    <div
                        key={index}
                        onClick={() => setShowModalXL({ setShow: true, data: Product })}
                        style={{ margin: "10px 0" }}
                    >
                        <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "row", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                            <div className="cardYouMayAlsoLike" style={{ borderRadius: "5px" }}>
                                <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                                <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                            </div>
                            {
                                Product.offers &&
                                <div style={{ position: "absolute", top: "10px", left: Product.disable ? "90px" : "0px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: "50px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                                    {Product.discount! * -1} %
                                </div>
                            }
                            {
                                Product.disable &&
                                <div style={{ position: "absolute", top: "10px", left: "0", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: "80px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "10px" }}>
                                    {t("Market.Sold out")}
                                </div>
                            }
                            <div>
                                <div style={{ fontSize: "15px", padding: "5px", maxWidth: 130 }}><Langu {...Product.product!} /></div>
                                <div style={{ width: "100%", fontSize: "12px" }}>
                                    {
                                        Product.newPrice ?
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                                    {finshNumber(Product.price!).toLocaleString('en-US') + ' ₺'}
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    {finshNumber(Product.newPrice).toLocaleString('en-US') + ' ₺'}
                                                </div>
                                            </div>
                                            :
                                            <div style={{ textAlign: "center", color: "gray" }}>
                                                {finshNumber(Product.price!).toLocaleString('en-US') + ' ₺'}
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        {
            showModalXL.setShow &&
            <ModelXLAdmin show={showModalXL.setShow} setShowModalXL={setShowModalXL} data={showModalXL.data} />
        }
    </>)
}

export default AdminYouMayAlsoLike;
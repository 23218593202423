import React from "react";
import { Outlet } from "react-router-dom"
import HedarGuest from "../hedarGuest/HedarGuest";
import { motion } from 'framer-motion';
import FloatingButton from "../component/supportIcon/SupportIcon";
import Footer from "../../components/footer/Footer";


const pageTransition = {
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" }
};

const OutletGuest: React.FC = () => {
    return (<>
        <HedarGuest />
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageTransition}
            transition={{ duration: 0.1 }}
        >
            <Outlet />
            <FloatingButton />
            <Footer />
        </motion.div>
    </>)
}

export default OutletGuest;
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import AdminYouMayAlsoLike from "../../components/CardsProducts/AdminYouMayAlsoLike";
import AdminCardPeoduct from "../../components/CardsProducts/AdminCardProduct";
import { Brand, ProductSearch } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";



const SearchPageAdmin: React.FC = () => {
    const [dataProducts, setDataProducts] = useState<ProductSearch>()
    let params = useParams()
    const type = params.type
    const value = params.value
    const { t } = useTranslation()
    const [price, setPrice] = useState<string>("All")
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    const [checkStatus, setCheckStatus] = useState<string[]>([])
    const [checkUsage, setCheckUsage] = useState<string[]>([])
    const context = useContext(ChatContext)
    const { isMobile, baseCurrency } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        if (type === "brand") {
            setCheckBrand([value!])
        }
        if (type === "classification") {
            setCheckUsage([value!])
        }
        if (type === "statusCategory") {
            setCheckStatus([value!])
        }
    }, [type, value])

    useEffect(() => {
        axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchBarProducts`, {
            search: params.product, price: (Number(price) * baseCurrency), brand: checkBrand, classification: checkUsage, statusCategory: checkStatus
        })
            .then((doc) => setDataProducts(doc.data))
    }, [params, price, checkBrand, checkStatus, checkUsage])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };

    const handleCheckboxChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckStatus([...checkStatus!, value]);
        } else {
            setCheckStatus(checkStatus!.filter((brand) => brand !== value));
        }
    };

    const handleCheckboxChangeUsage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckUsage([...checkUsage!, value]);
        } else {
            setCheckUsage(checkUsage!.filter((brand) => brand !== value));
        }
    };


    return (<>
        <div>
            <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
                <div className="nameOffer" style={{ top: isMobile ? "calc(10vh + 100px)" : "calc(10vh + 250px)" }}>{t("Home.Search results")}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {
                    !isMobile &&
                    <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                        <div style={{ minHeight: '30vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                            <div>
                                <AdminYouMayAlsoLike />
                            </div>
                        </div>
                        <div style={{ minHeight: '10vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                    <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                        {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                            <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                                <input
                                                    type="radio"
                                                    id={`${pricee}`}
                                                    name="priceFiltar"
                                                    value={`${pricee}`}
                                                    checked={price === `${pricee}`}
                                                    onChange={handleChange}
                                                />
                                                {`${pricee}`}
                                                <span className="radio-custom"></span>
                                            </label>
                                        ))}
                                        <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                            <input
                                                type="radio"
                                                id="300"
                                                name="priceFiltar"
                                                value="300"
                                                checked={price === "300"}
                                                onChange={handleChange}
                                            />
                                            {t("Market.more than 300")}
                                            <span className="radio-custom"></span>
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh', borderBottom: "1px solid #dddddd" }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        dataBrands?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand.name.en}
                                                checked={checkBrand.includes(brand.name.en)}
                                                label={<Langu {...brand.name} />}
                                                value={brand.name.en}
                                                onChange={handleCheckboxChange}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh', borderBottom: "1px solid #dddddd" }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.status`)}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        ["newStatus", "asNew", "used"]?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand}
                                                label={t(`Market.${brand}`)}
                                                checked={checkStatus.includes(brand)}
                                                value={brand}
                                                onChange={handleCheckboxChangeStatus}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.Usage`)}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        ["gaming", "desktop"]?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand}
                                                label={t(`Market.${brand}`)}
                                                value={brand}
                                                checked={checkUsage.includes(brand)}
                                                onChange={handleCheckboxChangeUsage}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%" }}>
                    <div style={{ display: "flex", gap: isMobile ? 5 : "20px", marginTop: "30px", padding: isMobile ? "0 10px 40px 10px" : "0 40px", flexWrap: "wrap", justifyContent: "center", maxWidth: "1440px", width: "100%", alignContent: "start" }}>
                        {
                            dataProducts?.movies?.length! > 0 ? dataProducts?.movies?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                                <AdminCardPeoduct Product={Product} dragging={false} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                        }
                    </div>
                </div>
            </div >
        </div>

    </>)
}

export default SearchPageAdmin;
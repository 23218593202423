import axios from "axios"
import { useEffect, useState } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { LoadingBtn } from "../../components/laoding/laoding"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"


const CreateUser: React.FC = () => {
    const param = useParams()
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Hedar.Sinup"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [showSuccessSinup, setShowSuccessSinup] = useState<boolean>(false)
    const [showErrSinup, setShowErrSinup] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [phone, setPhone] = useState<string | undefined>()
    const [errInput, setErrInput] = useState<string>('')
    const [successMsg, setSuccessMsg] = useState<string>()

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/VerifyTokenEmail/${param.token}`)
            .then(() => setSuccess(true))
            .catch(() => setSuccess(false))
    }, [])

    useEffect(() => {
        setErrInput('')
        setSuccessMsg('')
    }, [userName, password, confirmPassword, phone])

    const dataPost = { userName, password, confirmPassword, phone }

    const BtnCreate = async () => {
        setDisabledBtnCreate(true)
        setStatusBtnCreate(<LoadingBtn />)
        if (!userName || !password || !confirmPassword || !phone) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Hedar.Sinup"))
            setErrInput(t("Market.Please enter data"))
            return;
        }
        if (password != confirmPassword) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Hedar.Sinup"))
            setErrInput(t("Home.Account.Passwords do not match"))
            return;
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Auth/Sinup/${param.token}`, dataPost)
            .then((doc) => setSuccessMsg(doc.data))
            .catch(err => setErrInput(err.response?.data?.errors?.[0].msg))
        setStatusBtnCreate(t("Hedar.Sinup"))
        setDisabledBtnCreate(false)
    }


    return (<>
        {success ?
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 0 10%" }}>
                    <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Hedar.Sinup")}</h1>
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.name">
                            <Form.Label>user name :</Form.Label>
                            <Form.Control type="text" placeholder="" autoFocus onChange={e => { setUserName(e.target.value) }} />
                        </Form.Group>
                    </div>
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.Password">
                            <Form.Label>Password :</Form.Label>
                            <Form.Control type="password" placeholder="" autoFocus onChange={e => { setPassword(e.target.value) }} />
                        </Form.Group>
                    </div>
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.Confirm">
                            <Form.Label>Confirm password :</Form.Label>
                            <Form.Control type="password" placeholder="" autoFocus onChange={e => { setConfirmPassword(e.target.value) }} />
                        </Form.Group>
                    </div>
                    <div style={{ width: "100%", maxWidth: "700px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.Phone">
                            <Form.Label>Phone :</Form.Label>
                            <Form.Control type="NUMBER" placeholder="" autoFocus onChange={e => { setPhone((e.target.value) || undefined) }} />
                        </Form.Group>
                    </div>
                    {
                        successMsg &&
                        <div style={{ color: "green", fontWeight: "500", width: "50%", textAlign: "center", padding: "5px", backgroundColor: "rgb(199 237 200)", borderRadius: "5px", margin: 5 }}>{t('Hedar.Your account has been successfully registered.')}</div>
                    }
                    {errInput &&
                        <div style={{ color: "red", fontWeight: "500", width: "50%", textAlign: "center", padding: "5px", backgroundColor: "#ffe9e9", borderRadius: "5px", margin: 5 }}>{errInput}</div>
                    }
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                    </div>
                </div>
            </div>
            :
            <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", color: "red", alignItems: "center", fontSize: "20px" }}>
                انتهاء صلاحية الكود
            </div>
        }
        <Alert show={showSuccessSinup} variant={"success"} style={{ position: "fixed", top: '80px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowSuccessSinup(false)} dismissible>
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Market.Error message")}
            </Alert.Heading>
            <p>
                {t("Hedar.Account registration error")}
            </p>
        </Alert>
        <Alert show={showErrSinup} variant={"success"} style={{ position: "fixed", top: '80px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowErrSinup(false)} dismissible>
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Home.Confirmation message")}
            </Alert.Heading>
            <p>
                {t("Hedar.Your account has been successfully registered.")}
            </p>
        </Alert>
    </>)
}

export default CreateUser;
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Offers } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface GetOffersAndDeleteProps {
    refreshData: boolean
}

const GetOffersAndDelete: React.FC<GetOffersAndDeleteProps> = ({ refreshData }) => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [dataOffers, setDataOffers] = useState<Offers[]>()
    const [hoverCloseBtn, setHoverCloseBtn] = useState<string | null>(null)
    const [idDelet, setIdDelete] = useState<string>()
    const [showModale, setShowModale] = useState<boolean>(false)
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Offers/GetOffers`)
            .then((doc) => setDataOffers(doc.data))
            .catch((err) => console.log(err))
    }, [showModale, refreshData])

    const handelDeleteOffer = async (id: string) => {
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Offers/DeleteOffer/${id}`,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData?.user?.token,
                }
            }
        )
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
        setShowModale(false)
    }

    return (<>
        <div style={{ display: "flex", gap: 30, marginTop: 50, width: isMobile ? "auto" : "100%", flexWrap: "wrap" }}>
            {
                dataOffers?.map((offer, index) => (
                    <div
                        key={index}
                        onMouseEnter={() => setHoverCloseBtn(offer._id)}
                        onMouseLeave={() => setHoverCloseBtn(null)}
                        style={{ position: "relative" }}
                    >
                        {
                            isMobile ?
                                <CloseButton
                                    style={{ position: "absolute", top: 0, left: 0, zIndex: 10, color: "gray" }}
                                    onClick={() => { setShowModale(true); setIdDelete(offer._id) }}
                                />
                                :
                                hoverCloseBtn === offer._id &&
                                <CloseButton
                                    style={{ position: "absolute", top: 0, left: 0, zIndex: 10, color: "gray" }}
                                    onClick={() => { setShowModale(true); setIdDelete(offer._id) }}
                                />
                        }
                        <img style={{ width: isMobile ? 70 : 100, maxHeight: isMobile ? 70 : 100 }} src={`${process.env.REACT_APP_URL_API}/API/files/Offers/${offer?.file?.[0]}`} />
                    </div>
                ))
            }
        </div>
        <Modal show={showModale} onHide={() => setShowModale(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>
                    {t("Home.Confirmation message")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t("Home.Do you really want to delete the category?")}
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => setShowModale(false)}>{t("Hedar.Close")}</Button>
                <Button variant="danger" onClick={() => handelDeleteOffer(idDelet!)}>{t("Hedar.Delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default GetOffersAndDelete;
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { Category } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface Categoryprops {
    en: string;
    ar: string;
    he: string;
}

interface CategorysPostProps {
    name: {
        en: string;
        ar: string;
        he: string;
    };
    Categories: {
        en: string;
        ar: string;
        he: string;
    }[];
}
const CreateCategorys = () => {
    let userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const { t } = useTranslation()
    const [category, setCategory] = useState<string>("")
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [nameEN, setNameEN] = useState<string>()
    const [nameAR, setNameAR] = useState<string>()
    const [nameHE, setNameHE] = useState<string>()
    const [categoryEN, setCategoryEN] = useState<string>('')
    const [categoryAR, setCategoryAR] = useState<string>('')
    const [categoryHE, setCategoryHE] = useState<string>('')
    const [categorysPost, setCategorysPost] = useState<Categoryprops[]>([])
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>("اضافة تصنيف")
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => { setDataCategory(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setCategory(dataCategory?.[0]?._id!)
    }, [dataCategory])

    const dataPost: CategorysPostProps = { name: { en: nameEN!, ar: nameAR!, he: nameHE! }, Categories: categorysPost }

    const addCategorys = () => {
        setCategorysPost(prevColors => [...prevColors, { en: categoryEN, ar: categoryAR, he: categoryHE }]);
    };

    const BtnDeleteCategory = () => {
        setCategorysPost(prevColors => prevColors.filter(c => c.en !== hoveredItemId));
    }

    const BtnCreateCategorys = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PatchAddCategorys/${category}`, { Categorys: dataPost },
            {
                headers: {
                    Authorization: "Bearar " + token,
                }
            })
            .then((doc) => console.log(doc))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnCreate("اضافة تصنيف")
                setDisabledBtnCreate(false)
            })
    }


    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: isMobile ? 10 : "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? "40px 10px" : "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>انشاء تصنيفات</h1>
                <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                    <Form.Label>{t("Market.Category name")} :</Form.Label>
                    <Form.Select onChange={e => { setCategory(e.target.value) }}>
                        {
                            dataCategory?.map((category, index) => (
                                <option key={index} value={category._id}><Langu {...category.name} /></option>
                            ))
                        }
                        {/* <option value={JSON.stringify({ en: "Packages", ar: "باكيجات", he: "باكيجات" })}>{t("Home.Packages")}</option> */}
                    </Form.Select>
                </Form.Group>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.اسم التصنيف")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" onChange={e => { setNameEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setNameAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="Türkçe" onChange={e => { setNameHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Label>{t("تصنيفات")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" onChange={e => { setCategoryEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setCategoryAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="Türkçe" onChange={e => { setCategoryHE(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addCategorys}>{t("add")}</Button>
                    </Form.Group>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                    {categorysPost?.map((co, index) => (
                        <div
                            key={index}
                            onMouseEnter={() => setHoveredItemId(co.en)}
                            onMouseLeave={() => setHoveredItemId(null)}
                            style={{ border: "1px solid #dddddd", padding: "10px 5px", cursor: "pointer", display: "flex", flexDirection: "column" }}
                            onClick={BtnDeleteCategory}
                        >
                            <div>
                                <Langu {...co} />
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ padding: 30 }}>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreateCategorys} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>
            </div>
        </div>
    </>)
}

export default CreateCategorys;
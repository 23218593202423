import React, { useContext, useEffect, useState } from "react";
import { Product } from "../../../Interfaces";
import CardProducts from "../CardProductsGuest";
import { ThemeColor } from "../../../theme";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { ChatContext } from "../../../components/context/ChatContext";

interface ProductsYouGuestProps {
    category: string
}

const ProductsYouGuest: React.FC<ProductsYouGuestProps> = ({ category }) => {
    const { t } = useTranslation()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const [limit, setLimit] = useState<number>(20)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts/${category}/${limit}`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }, [category, limit])

    const BtnViewMore = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 10)
    }

    return (<>
        <div style={{ maxWidth: "1660px" }}>
            <div style={{ color: ThemeColor.bgColor(1), fontSize: 40, fontWeight: 700, textAlign: "center", width: "100%", margin: "40px 0" }}>تصفح المزيد</div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: isMobile ? 5 : 10, margin: isMobile ? "0 0 30px 0" : "0 0 100px 0", width: "100%", padding: isMobile ? "0 10px" : "0 40px" }}>
                {
                    dataProducts?.map((product, index) => (
                        <CardProducts key={index} Product={product} />
                    ))
                }
            </div>
            <div style={{ width: "100%", margin: "40px 0", textAlign: "center" }}>
                <Button bsPrefix="BtnBNB" onClick={BtnViewMore}>{statusBtnViewMore}</Button>
            </div>
        </div>
    </>)
}

export default ProductsYouGuest;
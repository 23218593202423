import { Button, Stack } from "react-bootstrap";
import Langu from "../languge/languge";
import { decrementItems, incrementItems } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Product } from "../../Interfaces";
import { ChatContext } from "../context/ChatContext";


interface CartItemProps {
    data: {
        data: Product;
        quantity: number;
        color: string;
    }
}


const CartItem: React.FC<CartItemProps> = ({ data }) => {
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext)
    const { baseCurrency } = chatContext!
    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${data.data._id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const finshNumber = (num: number) => {
        const afterNum = Math.round(Number(num) * baseCurrency)
        const remainder = afterNum % 10;
        if (remainder < 5) {
            return afterNum - remainder; // إذا كان الباقي أقل من 5، قم بطرح الباقي
        } else {
            return afterNum + (10 - remainder); // إذا كان الباقي أكبر أو يساوي 5، قم بإضافة الفرق
        }
    }

    return (<>
        <Stack style={{ display: "flex", maxHeight: "100px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: "4px 0" }}>
            <img style={{ height: "80px", width: "100px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.data?.file?.[0]}`} alt={data.data.product?.en} />
            <div>
                <div>
                    {Langu(data?.data?.product!)}
                </div>
                <div>
                    x{data.quantity}
                </div>
                {data.color &&
                    <div style={{ width: '50px', textAlign: "center" }}>
                        <div style={{ maxWidth: 20, minWidth: 20, maxHeight: 20, minHeight: 20, backgroundColor: data.color }}></div>
                    </div>
                }
            </div>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ textAlign: "center" }}>
                    {
                        data.data.newPrice ?
                            <div>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {(finshNumber(data.data.price!) * data.quantity).toLocaleString('en-US')} ₺
                                </div>
                                <div style={{ color: "red" }}>
                                    {(finshNumber(data.data.newPrice!) * data.quantity).toLocaleString('en-US')} ₺
                                </div>
                            </div>
                            :
                            <div>
                                {(finshNumber(data.data.price!) * data.quantity).toLocaleString('en-US')} ₺
                            </div>
                    }
                </div>
                <div style={{ display: "flex" }}>
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} disabled={dataProduct?.number === data.quantity} style={{ width: "30px", height: "30px", padding: "0" }}>+</Button>
                    </div>
                    <div style={{ width: "20px", textAlign: "center" }}>
                        {data.quantity}
                    </div>
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} style={{ width: "30px", height: "30px", padding: "0" }}>-</Button>
                    </div>
                </div>
            </div>
        </Stack>
    </>)
}


export default CartItem;
import { Form } from "react-bootstrap";
import img1 from '../../files/HedarGuest/IconLang2e.jpg'
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoEarthSharp } from "react-icons/io5";
import { ThemeColor } from "../../theme";
import { ChatContext } from "../../components/context/ChatContext";


const I18nClient: React.FC = () => {
    const { i18n } = useTranslation()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!
    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [])
    const BtnLanguge = (lan: string | undefined) => (
        i18n.changeLanguage(lan),
        window.location.reload()
    )
    return (<>
        <Form.Select style={{
            paddingLeft: isMobile ? 30 : "40px",
            paddingRight: isMobile ? 5 : "20px",
            width: isMobile ? 70 : "100px",
            height: "35px",
            appearance: "none",
            background: `url(${img1}) no-repeat 10px center`,
            backgroundSize: "30px 25px, 100%",
            backgroundColor: ThemeColor.bgColor(1),
            textAlign: "center",
            cursor: "pointer",
            color: "#dfcaa3",
            border: "1px solid #dfcaa3"
        }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
            <option value={"ar"}>AR</option>
            <option value={"en"}>EN</option>
            <option value={"tr"}>TR</option>
        </Form.Select>
    </>)
}

export default I18nClient;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { Category } from "../../../Interfaces";
import Langu from "../../../components/languge/languge";

interface CheckedCategories {
    name: {
        en: string,
        ar: string,
        he: string,
    },
    Categories: {
        en: string,
        ar: string,
        he: string,
    }[]
}

const UpDateCategory: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const params = useParams()
    const { t } = useTranslation()
    const [dataCategory, setDataCategory] = useState<Category>()
    const [errInput, setErrInput] = useState<string>('')
    const [checkStatus, setCheckStatus] = useState<string[]>([])
    const [checkUsage, setCheckUsage] = useState<string[]>([])
    const [nameEN, setNameEN] = useState<string>('')
    const [nameAR, setNameAR] = useState<string>('')
    const [nameHE, setNameHE] = useState<string>('')
    const [photo, setPhoto] = useState<File[]>([])
    const [statusBtn, setStatusBtn] = useState<any>("Put")
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)
    const [checkedCategories, setCheckedCategories] = useState<CheckedCategories[]>();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategoryId/${params.id}`)
            .then((doc) => setDataCategory(doc.data))
            .catch((err) => console.log(err))
    }, [reload])

    useEffect(() => {
        setCheckStatus(dataCategory?.statusCategory!)
        setCheckUsage(dataCategory?.classification!)
        setNameEN(dataCategory?.name.en!)
        setNameAR(dataCategory?.name.ar!)
        setNameHE(dataCategory?.name.he!)
        setCheckedCategories(dataCategory?.Categorys!)
    }, [dataCategory])

    useEffect(() => {
        setErrInput('')
    }, [nameEN, nameAR, nameHE, photo])

    const handleCheckboxChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setCheckStatus((prev) => {
            if (checked === "null") {
                if (!checkStatus.includes("null")) {
                    return ['null']
                } else {
                    return []
                }
            } else if (prev.includes(checked)) {
                return prev.filter((item) => item !== checked);
            }
            return [...prev, checked];
        });
    };

    const handleCheckboxChangeUsage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setCheckUsage((prev) => {
            if (checked === "null2") {
                if (!checkUsage.includes("null2")) {
                    return ['null2']
                } else {
                    return []
                }
            } else if (prev.includes(checked)) {
                return prev.filter((item) => item !== checked);
            }
            return [...prev, checked];
        });
    };

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }


    const PutCategory = async () => {
        setDisabledBtn(true)
        setStatusBtn(<LoadingBtn />)
        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("nameEN", nameEN)
        updatedFormData.append("nameAR", nameAR)
        updatedFormData.append("nameHE", nameHE)
        updatedFormData.append("statusCategory", JSON.stringify(checkStatus));
        updatedFormData.append("classification", JSON.stringify(checkUsage));
        updatedFormData.append("Categorys", JSON.stringify(checkedCategories));

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        await axios.patch(`${process.env.REACT_APP_URL_API}/API/Products/PatchCategory/${dataCategory?._id}`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => setReload(!reload))
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtn("Put")
                setDisabledBtn(false)
            })
    }

    const handelCheckedCategorys = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        const Value = JSON.parse(value)

        setCheckedCategories((prev) => {
            if (!prev) return prev;

            return prev.map((category) => {
                if (category.name.en === name) {
                    const updatedCategories = checked
                        ? [...category.Categories, { en: Value.en, ar: Value.ar, he: Value.he }]
                        : category.Categories.filter((cat) => cat.en !== Value.en);
                    return { ...category, Categories: updatedCategories };
                }
                return category;
            });
        });
    };

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: 700, margin: "40px 0", width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ fontSize: 20, display: "flex", flexDirection: "column", textAlign: "center", border: "1px solid #dddddd", padding: 40, justifyContent: "center", gap: 30, alignItems: "center" }}>
                    <div>
                        <img draggable={false} style={{ width: 150, height: 150 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${dataCategory?.file ? dataCategory.file[0] : ""}`} />
                        {dataCategory?.file?.[1] && <img draggable={false} style={{ width: 150, height: 150 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${dataCategory?.file ? dataCategory.file[1] : ""}`} />}
                    </div>
                    <div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Market.Photo")} :</Form.Label>
                            <Form.Control type="file" onChange={handelPhoto} multiple />
                        </Form.Group>
                        <div>
                            {photo.length > 0 &&
                                <div style={{ display: "flex", gap: "15%", height: 250 }}>
                                    <h3>الصور التي تم اختيارها:</h3>
                                    {photo.map((i, index) => (
                                        <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                            <p>الصورة {index + 1}: {i.name}</p>
                                            <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                            <div style={{ display: "flex", gap: 10 }}>
                                                {index > 0 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                                        <FaArrowUp size={20} />
                                                    </Button>
                                                )}
                                                {index < photo.length - 1 && (
                                                    <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                                        <FaArrowDown size={20} />
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ display: "flex", gap: "10px" }}>
                            <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setNameEN(e.target.value) }} value={nameEN} />
                            <Form.Control type="text" placeholder="العربية" onChange={e => { setNameAR(e.target.value) }} value={nameAR} />
                            <Form.Control type="text" placeholder="Türkçe" onChange={e => { setNameHE(e.target.value) }} value={nameHE} />
                        </Form.Group>
                    </div>
                    <div style={{ maxWidth: 300 }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.status`)} : </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Form style={{ display: "flex", gap: '10px', justifyContent: "center" }}>
                                <Form.Label htmlFor="null">بلا </Form.Label>
                                <Form.Check id="null" value={"null"} name="null" onChange={handleCheckboxChangeStatus} multiple />
                                {
                                    ["newStatus", "asNew", "used"]?.map((brand, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={brand}
                                            label={t(`Market.${brand}`)}
                                            checked={checkStatus?.includes(brand)}
                                            disabled={checkStatus?.includes("null") || false}
                                            value={brand}
                                            onChange={handleCheckboxChangeStatus}
                                        />
                                    ))
                                }
                            </Form>
                        </div>
                    </div>
                    <div style={{ maxWidth: 300 }}>
                        <div style={{ minHeight: '10vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t(`Hedar.Notifications.Usage`)} : </div>
                            <div>
                                <Form style={{ display: "flex", gap: '10px', justifyContent: "center" }}>
                                    <Form.Label htmlFor="null2">بلا </Form.Label>
                                    <Form.Check id="null2" value={"null2"} name="null2" onChange={handleCheckboxChangeUsage} multiple />
                                    {
                                        ["gaming", "desktop"]?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand}
                                                label={t(`Market.${brand}`)}
                                                value={brand}
                                                checked={checkUsage?.includes(brand)}
                                                disabled={checkUsage?.includes("null2") || false}
                                                onChange={handleCheckboxChangeUsage}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                    {dataCategory?.Categorys?.map((Category, index) => (
                        <div style={{ minHeight: '10vh', }} key={index}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>
                                <Langu {...Category?.name} />
                            </div>
                            <div>
                                <Form style={{ display: "flex", justifyContent: "center", gap: '10px' }}>
                                    {
                                        Category?.Categories?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand.en}
                                                label={<Langu {...brand} />}
                                                value={JSON.stringify(brand)}
                                                name={Category.name.en}
                                                checked={checkedCategories?.some(
                                                    (c) => c.name.en === Category.name.en && c.Categories.some((cat) => cat.en === brand.en)
                                                )}
                                                onChange={handelCheckedCategorys}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                    ))}
                    <div>
                        <Button bsPrefix="BtnBNB" onClick={PutCategory} disabled={disabledBtn}>{statusBtn}</Button>
                    </div>
                    {/* sadsa dsa" :  {dataCategory?.classification} */}
                </div>
            </div>
        </div>
    </>)
}

export default UpDateCategory;
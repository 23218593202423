import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import './Hedar.client.css'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/userSlice";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { RootState } from "../../redux/store";



const AccuntDropdownClient: React.FC = () => {
    const cookie = new Cookies()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const nav = useNavigate()
    const userData = useSelector((state: RootState) => state.userData)

    const [accuntHover, setAccuntHover] = useState<boolean>(false)

    const LogoutBtn = async () => {
        await axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/Logout`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then(() => {
                dispatch(setUser({ token: undefined, refreshToken: undefined, userDetals: undefined }))
                cookie.remove("bearer", { path: "/" })
                nav('/guest/homePage')
            })
            .catch((err) => console.log(err))
    }

    return (<>
        <div
            style={{ minHeight: "40px", display: "flex", alignItems: "center" }}
            onMouseEnter={() => setAccuntHover(true)}
            onMouseLeave={() => setTimeout(() => {
                setAccuntHover(false)
            }, 200)}
            onClick={() => setAccuntHover(!accuntHover)}
        >
            <DropdownButton
                show={accuntHover}
                size="sm"
                bsPrefix={"AccuntDropdownButton"}
                // variant="liht"
                title={<FaRegUser size={"25px"} />}
            >
                <div style={{ textAlign: "start", padding: '0 10px', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        {t("Hedar.Welcome")} {userData.user.userDetals?.userName}
                    </div>
                    <div style={{ height: "40px", width: "40px", border: "1px solid gray", borderRadius: "50%", backgroundColor: "#dddddd" }}>

                    </div>
                </div>
                <Dropdown.Divider />
                <Dropdown.Item style={{ textAlign: "start" }} onClick={() => { nav('/client/myAccount/myOrders'); setAccuntHover(false) }} eventKey="1">{t("Hedar.my Orders")}</Dropdown.Item>
                <Dropdown.Item style={{ textAlign: "start" }} onClick={() => { nav(`/client/myAccount/AccountDetals/${userData?.user?.userDetals?._id}`); setAccuntHover(false) }} eventKey="2">{t("Hedar.AccountDetals")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={LogoutBtn} style={{ color: 'red', textAlign: "start" }} eventKey="4">{t("Hedar.Logout")}</Dropdown.Item>
            </DropdownButton>
        </div>
    </>)
}

export default AccuntDropdownClient;
import { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import BtnCreateProduct from "./BtnCreateProduct";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { Brand, Category } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

const CreateProduct: React.FC = () => {
    const { t } = useTranslation()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [category, setCategory] = useState<string>("")
    const [categoryDetails, setCategoryDetails] = useState<Category>()
    const [brand, setBrand] = useState<string>('')
    const [productEN, setProductEN] = useState<string>('')
    const [productAR, setProductAR] = useState<string>('')
    const [productHE, setProductHE] = useState<string>('')
    const [informationEN, setInformationEN] = useState<string>('')
    const [informationAR, setInformationAR] = useState<string>('')
    const [informationHE, setInformationHE] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [colorsPost, setColorsPost] = useState<(string | undefined)[]>([]);
    const [price, setPrice] = useState<number | undefined>()
    const [newPrice, setNewPrice] = useState<number | undefined>()
    const [number, setNumber] = useState<number | undefined>()
    const [photo, setPhoto] = useState<File[]>([])
    const [offerStatus, setOfferStatus] = useState<boolean>(false)
    const [hoveredItemId, setHoveredItemId] = useState<string | null | undefined>(null)
    const [statusCategory, setStatusCategory] = useState<string>("null")
    const [classification, setClassification] = useState<string>("null2")
    const [checkedCategorys, setCheckedCategorys] = useState<Record<string, string>>({})
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => { setDataCategory(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        category && axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
        category && axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategory/${JSON.parse(category).en}`)
            .then((doc) => setCategoryDetails(doc.data))
            .catch((err) => console.log(err))
    }, [category])


    useEffect(() => {
        setTimeout(() => {
            if (dataCategory && dataCategory.length > 0) {
                setCategory(JSON.stringify({
                    en: dataCategory[0].name.en,
                    ar: dataCategory[0].name.ar,
                    he: dataCategory[0].name.he,
                }))
                setBrand(JSON.stringify({
                    en: dataBrands?.[0].name.en,
                    ar: dataBrands?.[0].name.ar,
                    he: dataBrands?.[0].name.he,
                }))
            }
        }, 500)
    }, [dataCategory])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    const addColors = () => {
        setColorsPost(prevColors => [...prevColors, (color || '#000000')]);
    };

    const BtnDeleteColor = () => {
        setColorsPost(prevColors => prevColors.filter(c => c !== hoveredItemId));
    }


    const handelStatusCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setStatusCategory(checked);
    };

    const handelClassification = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.value;
        setClassification(checked);
    };


    const handelCheckedCategorys = (categoryKey: string, value: string) => {
        setCheckedCategorys((prev) => ({
            ...prev,
            [categoryKey]: value, // تحديث الاختيار للتصنيف المعين
        }));
    }

    const changeImageOrder = (fromIndex: number, toIndex: number) => {
        const newPhotos = [...photo];
        const [movedPhoto] = newPhotos.splice(fromIndex, 1);
        newPhotos.splice(toIndex, 0, movedPhoto);
        setPhoto(newPhotos);
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: isMobile ? 10 : "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: isMobile ? "40px 10px" : "20px 10% 30px 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Product")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", gap: "10px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Select onChange={e => { setCategory(e.target.value) }} value={category}>
                            {
                                dataCategory?.map((category, index) => (
                                    <option key={index} value={JSON.stringify({ en: category.name.en, ar: category.name.ar, he: category.name.he })}><Langu {...category.name} /></option>
                                ))
                            }
                            {/* <option value={JSON.stringify({ en: "Packages", ar: "باكيجات", he: "باكيجات" })}>{t("Home.Packages")}</option> */}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Brand name")} :</Form.Label>
                        <Form.Select onChange={e => { setBrand(e.target.value) }} value={brand}>
                            {
                                dataBrands?.map((brands, index) => (
                                    <option key={index} value={JSON.stringify({ en: brands.name.en, ar: brands.name.ar, he: brands.name.he })}><Langu {...brands.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <div> {t(`Hedar.Notifications.status`)} : </div>
                    <Form.Check className="mb-3" style={{ display: "flex", gap: 20 }} >
                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                            <Form.Label htmlFor="null">بلا </Form.Label>
                            <Form.Check id="null" type="radio" value={"null"} name="statusCategory" checked={statusCategory === "null"} onChange={handelStatusCategory} multiple />
                        </div>
                        {categoryDetails?.statusCategory?.map((category, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                <Form.Label htmlFor={`${category}`}>{t(`Market.${category}`)} </Form.Label>
                                <Form.Check type="radio" id={`${category}`} value={`${category}`} checked={statusCategory === category} name="statusCategory" onChange={handelStatusCategory} multiple />
                            </div>
                        ))}
                    </Form.Check>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <div> {t(`Hedar.Notifications.Usage`)} : </div>
                    <Form.Check className="mb-3" style={{ display: "flex", gap: 20 }} >
                        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                            <Form.Label htmlFor="null2">بلا </Form.Label>
                            <Form.Check type="radio" id="null2" value={"null2"} name="classification" checked={classification === "null2"} onChange={handelClassification} multiple />
                        </div>
                        {categoryDetails?.classification?.map((category, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                <Form.Label htmlFor={`${category}`}> {t(`Market.${category}`)}</Form.Label>
                                <Form.Check type="radio" id={`${category}`} name="classification" value={`${category}`} checked={category === classification} onChange={handelClassification} multiple />
                            </div>
                        ))}
                    </Form.Check>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    {categoryDetails?.Categorys?.map((item: any, index: number) => (
                        <div key={index}>
                            <div> <Langu {...item.name} /> : </div>
                            <Form.Check className="mb-3" style={{ display: "flex", gap: 20 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                    <Form.Label htmlFor={`null--${index}`}>بلا</Form.Label>
                                    <Form.Check
                                        type="radio"
                                        id={`null--${index}`}
                                        value={"null"}
                                        name={`Categorys-${index}`}
                                        checked={checkedCategorys[item.name.en] === "null"}
                                        onChange={() => handelCheckedCategorys(item.name.en, "null")}
                                    />
                                </div>
                                {item?.Categories?.map((category: any, subIndex: number) => (
                                    <div key={subIndex} style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                                        <Form.Label htmlFor={`${category}-${index}-${subIndex}`}> <Langu {...category} /></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            id={`${category}-${index}-${subIndex}`}
                                            name={`Categorys-${index}`}
                                            value={category.en}
                                            checked={checkedCategorys[item.name.en] === category.en}
                                            onChange={() => handelCheckedCategorys(item.name.en, category.en)}
                                        />
                                    </div>
                                ))}
                            </Form.Check>
                        </div>
                    ))}
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Product name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setProductEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setProductAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="Türkçe" onChange={e => { setProductHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.information")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                        <Form.Control as="textarea" placeholder="English" onChange={e => { setInformationEN(e.target.value) }} />
                        <Form.Control as="textarea" placeholder="العربية" onChange={e => { setInformationAR(e.target.value) }} />
                        <Form.Control as="textarea" placeholder="Türkçe" onChange={e => { setInformationHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px" }}>
                        <Form.Label>{t("colors")} :</Form.Label>
                        <Form.Control type="color" placeholder="" onChange={e => { setColor(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addColors}>{t("add")}</Button>
                        {colorsPost?.map((co, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredItemId(co)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                style={{ backgroundColor: co, minWidth: 28, minHeight: 28, border: "1px solid #dddddd", margin: "10px 5px", cursor: "pointer" }}
                                onClick={BtnDeleteColor}
                            >
                            </div>
                        ))}
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.offer?")} style={{ margin: "20px 0" }} onChange={((e) => setOfferStatus(e.target.checked))} />
                    {offerStatus ?
                        <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                            <Form.Label form="Price">{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                            <Form.Label form="newPrice">{t("Market.newPrice")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setNewPrice(parseFloat(e.target.value) || undefined) }} />
                            <div style={{ width: "100%", padding: "10px" }}>{t("Market.discount")} : {discount?.toFixed(0)}%</div>
                        </Form.Group>
                        :
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Price">
                        <Form.Label>{t("Market.Available number")} :</Form.Label>
                        <Form.Control type="number" placeholder="" onChange={e => { setNumber(parseFloat(e.target.value) || undefined) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photos")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                    <div>
                        {photo.length > 0 &&
                            <div style={{ display: "flex", gap: "15%", height: 250 }}>
                                <h3>الصور التي تم اختيارها:</h3>
                                {photo.map((i, index) => (
                                    <div key={index} style={{ marginBottom: '10px', display: "flex", flexDirection: 'column', justifyContent: "space-between" }}>
                                        <p>الصورة {index + 1}: {i.name}</p>
                                        <img src={URL.createObjectURL(i)} alt={`photo-${index}`} width="100" />
                                        <div style={{ display: "flex", gap: 10 }}>
                                            {index > 0 && (
                                                <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index - 1)}>
                                                    <FaArrowUp size={20} />
                                                </Button>
                                            )}
                                            {index < photo.length - 1 && (
                                                <Button bsPrefix="BtnBNB" style={{ fontSize: 10, padding: "0 10px" }} onClick={() => changeImageOrder(index, index + 1)}>
                                                    <FaArrowDown size={20} />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <BtnCreateProduct brand={brand} category={category} colorsPost={colorsPost!} informationAR={informationAR} informationEN={informationEN} informationHE={informationHE}
                    newPrice={newPrice!} number={number!} offerStatus={offerStatus} photo={photo!} price={price!} productAR={productAR} productEN={productEN} productHE={productHE}
                    classification={classification} statusCategory={statusCategory} Categorys={checkedCategorys}
                />
            </div>
        </div>
    </>)
}


export default CreateProduct;
import { useContext, useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Category } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";


interface CardCategoryProp {
    category: Category;
    dragging?: boolean
}


const CardCategoryClient: React.FC<CardCategoryProp> = ({ category, dragging }) => {
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    return (<>
        <div style={{ width: isMobile ? "49%" : 300, position: "relative" }}>
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", border: "1px solid #dddddd", alignItems: "center", width: "100%", cursor: "pointer" }}>
                <div style={{ borderRadius: "5px" }} onClick={() => nav(`/client/market/category/${category?.name.en}`)}>
                    <img draggable={false} style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${category.file ? category.file[1] : ""}`} />
                </div>
            </div>
        </div >
    </>)
}

export default CardCategoryClient;
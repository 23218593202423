import "./Home.css"
import Offer1Guest from "./Offer1.guest"
import AllProducts from "./AllProducts"


const Home: React.FC = () => {
    return (<>
        <div>
            <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Offer1Guest />
                </div>
                <div>
                    <AllProducts />
                </div>
            </div>
        </div>
    </>)
}

export default Home;
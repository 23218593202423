import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ClientCardPeoduct from "../../components/CardsProduct/ClientCardPeoduct";
import { Form } from "react-bootstrap";
import ClientYouMayAlsoLike from "../../components/CardsProduct/ClientYouMayAlsoLike";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import { Brand, Product, ProductSearch } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";


const Favorites: React.FC = () => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData)
    const [dataUser, setDataUser] = useState<any>()
    const [dataProducts, setDataProducts] = useState<ProductSearch>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataNewProducts, setDataNewProducts] = useState<Product[]>()
    const [price, setPrice] = useState<string>("All")
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    const [checkTarget, setCheckTarget] = useState<string>("Alll")
    const [dataFavorite, setDataFavorite] = useState<Product[]>()
    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataNewProducts(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const dataSearch = { price, brand: checkBrand, target: checkTarget }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData?.user?.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUser(doc.data))
            .catch((err) => console.log(err))

    }, [])

    useEffect(() => {
        axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchProducts`, dataSearch)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [price, checkBrand, checkTarget])

    useEffect(() => {
        setDataFavorite(dataProducts?.movies?.filter((Product) =>
            dataUser?.favorite?.includes(Product._id!)
        ))
    }, [dataUser, dataProducts])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };

    const handleCheckboxChangeTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckTarget(e.target.value);
    };

    return (<>
        <div>
            <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
                <div className="nameOffer" style={{ top: isMobile ? "calc(10vh + 100px)" : "calc(10vh + 145px)" }}>{t("Hedar.Favorites")}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {
                    !isMobile &&
                    <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                        <div style={{ minHeight: '30vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                            <div>
                                <ClientYouMayAlsoLike />
                            </div>
                        </div>
                        <div style={{ minHeight: '10vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                    <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                        {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                            <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                                <input
                                                    type="radio"
                                                    id={`${pricee}`}
                                                    name="priceFiltar"
                                                    value={`${pricee}`}
                                                    checked={price === `${pricee}`}
                                                    onChange={handleChange}
                                                />
                                                {`${pricee}`}
                                                <span className="radio-custom"></span>
                                            </label>
                                        ))}
                                        <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                            <input
                                                type="radio"
                                                id="300"
                                                name="priceFiltar"
                                                value="300"
                                                checked={price === "300"}
                                                onChange={handleChange}
                                            />
                                            {t("Market.more than 300")}
                                            <span className="radio-custom"></span>
                                        </label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                            <div>
                                <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                    {
                                        dataBrands?.map((brand, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                id={brand.name.en}
                                                label={<Langu {...brand.name} />}
                                                value={brand.name.en}
                                                onChange={handleCheckboxChange}
                                            />
                                        ))
                                    }
                                </Form>
                            </div>
                        </div>
                        <div style={{ minHeight: '20vh' }}>
                            <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.target")}</div>
                            <div className="radio-container" style={{ paddingBottom: "10px", display: "flex", gap: "20px", flexDirection: "column" }}>
                                {["Alll", "Men's", "Women's"].map((target) => (
                                    <label key={`target-${target}`} className="radio-label" htmlFor={`${target}`} style={{ display: "flex", gap: "5px" }}>
                                        <input
                                            type="radio"
                                            id={`${target}`}
                                            name="target"
                                            value={`${target}`}
                                            checked={checkTarget === `${target}`}
                                            onChange={handleCheckboxChangeTarget}
                                        />
                                        {t(`Market.${target}`)}
                                        <span className="radio-custom"></span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {/* <ClientCardPeoduct dataProducts={dataFavorite!} dragging={false} justifyContent={true} /> */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%" }}>
                    <div style={{ display: "flex", gap: isMobile ? 5 : 20, marginTop: "30px", padding: isMobile ? "0 10px" : "0 40px", flexWrap: "wrap", justifyContent: "center", maxWidth: "1440px", width: "100%", alignContent: "start" , marginBottom: 40}}>
                        {
                            dataFavorite?.length! > 0 ? dataFavorite?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((Product, index) => (
                                <ClientCardPeoduct Product={Product} dragging={false} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                        }
                    </div>
                </div>
            </div >
        </div>

    </>)
}

export default Favorites;
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { IoSearchSharp } from "react-icons/io5";
import { Loading, LoadingBtn } from "../../../../components/laoding/laoding";
import { ThemeColor } from "../../../../theme";
import { ChatContext } from "../../../../components/context/ChatContext";


export interface OrderProps {
    data: any;
    _id?: string
    userId?: string;
    userName?: string;
    phone?: number;
    totalPrice?: number;
    paymentMethod?: string;
    dataItems?: {
        itemId?: string;
        product?: {
            ar: string;
            en: string;
            he: string
        };
        price?: number;
        offers?: boolean;
        quantity?: number;
        color?: string;
    }[];
    createdAt?: Date;
    address?: {
        country?: string;
        neighborhood?: string;
        street?: string;
        buildingNumber?: string | number;
        floor?: number;
        apartmentNo?: number;
    },
    status?: string;
    cancelOrder?: boolean;
}


const GetOrdersAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    const [search, setSearch] = useState<string>()
    const [download, setDownload] = useState<boolean>(false)
    const [limit, setLimit] = useState<number>(20)
    const [statusBtnViewMore, setStatusBtnViewMore] = useState<any>(t("Market.View more"))
    const context = useContext(ChatContext)
    const { isMobile } = context!

    const getSearchData = async () => {
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Orders/PostSearchOrders/${limit}`, { search },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setDataOrders(doc.data.movies); setDownload(false) })
            .catch((err) => console.log(err))
            .finally(() => {
                setStatusBtnViewMore(t("Market.View more"))
            })
    }

    useEffect(() => {
        setDownload(true)
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => { clearTimeout(debounce); }
    }, [search, limit])

    const BtnViewMore = () => {
        setStatusBtnViewMore(<LoadingBtn />)
        setLimit(limit + 10)
    }

    return (<>
        <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
            <div className="nameOffer" style={{ top: isMobile ? "calc(10vh + 100px)" : "calc(10vh + 220px)" }}>{t("Home.Orders")}</div>
        </div>
        <div style={{ width: "100%", margin: "30px 0" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Form className="d-flex search-bar" style={{ width: "80%", margin: "0", display: 'flex', maxWidth: 800 }}>
                    <InputGroup>
                        <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                            <IoSearchSharp style={{ color: ThemeColor.bgColor(1), fontSize: "20px" }} />
                        </InputGroup.Text>
                        <Form.Control
                            type="search"
                            placeholder={t("Hedar.Search")}
                            aria-label="Search"
                            value={search}
                            style={{ borderRadius: "0", borderRight: "none", margin: "0", padding: "0" }}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                </Form>
            </div>
        </div>
        {(dataOrders && download === false) ?
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ maxWidth: "1440px", width: "100%", padding: isMobile ? "0 " : "0 40px", marginBottom: 50 }}>
                    <div>
                        <Table striped style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "start" }}>#</th>
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Market.PayPage.id Order")}</th>
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.name")}</th>
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Market.Total price")}</th>
                                    {!isMobile &&
                                        <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Home.phone")}</th>
                                    }
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Market.PayPage.Payment methods")}</th>
                                    <th style={{ backgroundColor: "#000b29", color: "white", alignContent: "center", textAlign: "center" }}>{t("Market.status")}</th>
                                    <th style={{ width: "15%", backgroundColor: "#000b29", color: "white" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataOrders?.map((order, index) =>
                                        <tr key={order._id}>
                                            <td style={{ alignContent: "center", textAlign: "start" }}>{index + 1}</td>
                                            <td style={{ alignContent: "center", textAlign: "center" }}>{order._id}</td>
                                            <td style={{ alignContent: "center", textAlign: "center" }}>{order.userName}</td>
                                            <td style={{ alignContent: "center", textAlign: "center" }}>{order.totalPrice} ₺</td>
                                            {!isMobile &&
                                                <td style={{ alignContent: "center", textAlign: "center" }}>{order.phone}</td>
                                            }
                                            <td style={{ alignContent: "center", textAlign: "center" }}>{t(`Market.PayPage.${order.paymentMethod}`)}</td>
                                            <td style={{ alignContent: "center", textAlign: "center" }}>{t(`Market.PayPage.${order.status}`)}</td>
                                            <td style={{ alignContent: "center", textAlign: "end" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/market/orders/getOrder/${order._id}`)}>{t("Market.Order details")}</Button></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Button bsPrefix="BtnBNB" onClick={BtnViewMore}>{statusBtnViewMore}</Button>
                    </div>
                </div>
            </div>
            :
            <Loading />
        }
    </>)
}
export default GetOrdersAdmin;
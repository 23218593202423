import { useContext, useState } from "react";
import { CloseButton } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import ModeleDeleteCategory from "../../market/Modales/ModeleDeleteCategory";
import { Category } from "../../../Interfaces";
import { ChatContext } from "../../../components/context/ChatContext";

interface CardCategoryProp {
    category: Category;
}


const CardCategoryAdmin: React.FC<CardCategoryProp> = ({ category }) => {
    const nav = useNavigate()
    const chatContext = useContext(ChatContext)
    const { isMobile } = chatContext!

    const [showDeleteCategory, setShowDeleteCategory] = useState<boolean>(false)
    const [hoverCloseBtn, setHoverCloseBtn] = useState<string | null>(null)
    const [hoverSettingsBtn, setHoverSettingsBtn] = useState<boolean>(false)
    return (<>
        <div
            style={{ width: isMobile ? "49%" : 300, position: "relative" }}
            onMouseEnter={() => setHoverCloseBtn(category._id)}
            onMouseLeave={() => setHoverCloseBtn(null)}
        >
            {
                isMobile ?
                    <CloseButton
                        style={{ position: "absolute", top: 5, left: 5, zIndex: 10, color: "gray" }}
                        onClick={() => { setShowDeleteCategory(true) }}
                    />
                    :
                    hoverCloseBtn &&
                    <CloseButton
                        style={{ position: "absolute", top: 5, left: 5, zIndex: 10, color: "gray" }}
                        onClick={() => { setShowDeleteCategory(true) }}
                    />
            }
            {
                isMobile ?
                    <FontAwesomeIcon
                        onMouseEnter={() => setHoverSettingsBtn(true)}
                        onMouseLeave={() => setHoverSettingsBtn(false)}
                        fontSize={20}
                        style={{ position: "absolute", top: 7, left: 35, zIndex: 10, color: hoverSettingsBtn ? "black" : "gray", cursor: "pointer" }}
                        icon={faCog} className="me-2" onClick={() => nav(`/admin/market/updateCategory/${category._id}`)} />
                    :
                    hoverCloseBtn &&
                    <FontAwesomeIcon
                        onMouseEnter={() => setHoverSettingsBtn(true)}
                        onMouseLeave={() => setHoverSettingsBtn(false)}
                        fontSize={20}
                        style={{ position: "absolute", top: 7, left: 35, zIndex: 10, color: hoverSettingsBtn ? "black" : "gray", cursor: "pointer" }}
                        icon={faCog} className="me-2" onClick={() => nav(`/admin/market/updateCategory/${category._id}`)} />
            }
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", border: "1px solid #dddddd", alignItems: "center", width: "100%", cursor: "pointer" }}>
                <div style={{ borderRadius: "5px" }} onClick={() => nav(`/admin/market/category/${category?.name.en}`)}>
                    <img draggable={false} style={{ width: isMobile ? "100%" : 298, height: isMobile ? "100%" : 290 }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${category.file ? category.file[1] : ""}`} />
                </div>
            </div>
        </div>
        <ModeleDeleteCategory ModalDeleteCategory={setShowDeleteCategory} id={category._id} show={showDeleteCategory} />
    </>)
}

export default CardCategoryAdmin;